import { SCOPE_AVG_STEP_MAPPING } from 'common/sequence'
import { QueueItem } from 'shared/hooks/useQueue'
import { DateString, FirebaseKey, UserId } from 'shared/types/utils'
import { z } from 'zod'

export interface InstantLabels {
  labels: string[]
  ts: number
  start_ts: number
}

export type UserLabels = Record<UserId, InstantLabels>

interface Base {
  serial: string
  source: string
  why: string
}

export interface LabeledInstant extends Base {
  instant: string
  labelers: UserLabels
}

export type Stats = {
  count: number
}

export type DateStats = Record<DateString, Stats>

export interface Sequence extends Base, QueueItem {
  instants: string[]
  hint?: string
}

export type Sequences = Record<FirebaseKey, Sequence>

export const SCOPES = ['short', 'mid', 'long'] as const

export type Scope = (typeof SCOPES)[number]

export const AddInstantsToLabelingQueueSchema = z.array(
  z.object({
    instants: z.string().array().nonempty(),
    source: z.string(),
    why: z.string(),
    priority: z.coerce.number().nonnegative(),
    labelersTarget: z.coerce.number().positive(),
    avgStep: z.coerce
      .number()
      .positive()
      .optional()
      .default(SCOPE_AVG_STEP_MAPPING['mid']),
    hint: z.string().optional(),
    addRandomOffsetToRef: z.coerce.boolean().optional().default(false),
  }),
)

export type AddInstantsToLabelingQueueParameters = z.infer<
  typeof AddInstantsToLabelingQueueSchema
>
