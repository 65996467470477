import { ListObjectsCommand, S3Client, _Object } from '@aws-sdk/client-s3'
import { DateTime } from 'luxon'
import { s3BucketName } from '../../types/aws'
import { noonDate } from '../time'

const listAllObjects = async (
  prefix: string,
  s3Client: S3Client,
  nextMarker?: string,
): Promise<_Object[]> => {
  const input = {
    Bucket: s3BucketName,
    MaxKeys: 1000,
    Prefix: prefix,
    NextMarker: nextMarker,
  }
  const { Contents, IsTruncated, NextMarker } = await s3Client.send(
    new ListObjectsCommand(input),
  )
  return IsTruncated && NextMarker
    ? listAllObjects(prefix, s3Client, NextMarker).then((x) =>
        Contents ? Contents.concat(x) : x,
      )
    : Contents || []
}

export type S3Sound = {
  endTimestamp: number
  soundKey: string
  textKey?: string
}

export const fetchData = async (
  s3Client: S3Client,
  serial: string,
  prefix: string,
  prefixDate?: string,
) => {
  // Assumes a YYYY-MM-DDTHH:MM format for prefix, i.e. a valid dateTime
  const date = noonDate(DateTime.fromISO(prefixDate || prefix))

  const matcher = new RegExp(`${serial}\\/${date}\\/(.+)\\.(ogg|txt)`)

  const fullPrefix = `${serial}/${date}/${prefix}`

  const s3Objects = await listAllObjects(fullPrefix, s3Client)

  const soundsData: Record<string, S3Sound> = {}

  for (const { Key } of s3Objects) {
    if (!Key) continue
    const matches = Key.match(matcher)
    if (!matches) continue
    const [, soundDate, type] = matches

    if (!soundsData[soundDate]) {
      soundsData[soundDate] = {
        // No timezone, read with offset, convert to underlying timestamp
        endTimestamp: new Date(soundDate).getTime(),
        soundKey: `error-${soundDate}`, // should be filled below
      }
    }

    switch (type) {
      case 'ogg':
        soundsData[soundDate].soundKey = Key
        break
      case 'txt':
        soundsData[soundDate].textKey = Key
        break
    }
  }

  return Object.values(soundsData).sort(
    ({ endTimestamp: a }, { endTimestamp: b }) => a - b,
  ) as S3Sound[]
}
