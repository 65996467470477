import { Data } from './Stats'

export const StatsTable: React.FC<{
  data: Data | null
  columns: string[]
}> = ({ data, columns }) => {
  return (
    <table className="table-auto">
      <thead>
        <tr>
          {columns.map((column_name) => (
            <th
              key={column_name}
              className="border bg-blue-500 p-2 text-center"
            >
              {column_name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          Object.entries(data).map(([date, count]) => {
            return (
              <tr key={`${date}_${count}`}>
                <td key={date} className="border p-2 text-left">
                  {date}
                </td>
                <td key={count} className="border p-2 text-left">
                  {count.toFixed(0)}
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
