import { DateTime } from 'luxon'
import md5 from 'md5'
import { Scope } from './types'

export const SCOPE_AVG_STEP_MAPPING: Record<Scope, number> = {
  short: 60, // 1 minute
  mid: 8 * 60, // 8 minutes
  long: 20 * 60, // 20 minutes
}

export function formatInstant(dateTime: DateTime) {
  return dateTime
    .startOf('second')
    .setZone('Europe/Paris')
    .toISO({ suppressMilliseconds: true })
}

export function getSequenceKey(serial: string, instant: string): string {
  // Prevent a '.' before milliseconds, not permitted in firebase keys
  const dateTime = formatInstant(DateTime.fromISO(instant))
  return `${md5(`${serial}/${dateTime}`)}_${serial}-${dateTime}`
}

export interface SerialInstant {
  serial: string
  instant: string
}

export function extractSerialInstant(str: string) {
  const parts = str.split('/')
  if (parts.length !== 2) return undefined
  const [serial, instant] = parts
  if (serial.length !== 16 || isNaN(new Date(instant).getTime()))
    return undefined
  return { serial, instant }
}
