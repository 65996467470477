import { tryToReplaceUid } from 'common/tryToReplaceUid'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from 'shared/types/asyncState'
import { DateString } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { getPreviousDates } from 'shared/utils/timeRange'
import { onError } from 'shared/utils/web/error'
import { StatsTable } from './StatsTable'
import { auth } from './firebase'
import { get } from './firebaseMethods'

export type Data = Record<DateString, number>

export const Stats = () => {
  const [state, setState] = useState<AsyncState<Data>>(loadingState())

  const user = auth.currentUser

  useEffect(() => {
    async function fetchLabelerStats() {
      if (user === null) return
      try {
        const NB_DAYS_HISTORY = 30
        const now = DateTime.now().toISODate()
        const dates = getPreviousDates(now, NB_DAYS_HISTORY)

        const dateStats = await Promise.all(
          dates.map((date) => get(`stats/${user.uid}/${date}`)),
        )

        const signUpDate = new Date(user?.metadata?.creationTime ?? 0)
          .toISOString()
          .split('T')[0]

        const data: Data = {}
        for (const [idx, stats] of dateStats.entries()) {
          const date = dates[idx]
          const count = stats?.count ?? 0
          if (date >= signUpDate) data[date] = count
        }

        setState(dataState(data))
      } catch (error) {
        setState(errorState(new Error('An error occured')))
        onError(error)
      }
    }

    fetchLabelerStats()
  }, [user])

  if (user === null) {
    return (
      <div className="flex min-h-screen flex-col justify-center text-center">
        Utilisateur inconnu
      </div>
    )
  }

  const userName = tryToReplaceUid(user.uid, user?.email)

  return (
    <div className="flex min-h-screen w-screen grow flex-col p-8">
      <div className="text-4xl font-bold">{`Statistiques | ${userName}`}</div>
      {state.loading ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          Chargement...
        </div>
      ) : state.error ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          Une erreur est survenue
        </div>
      ) : !isObjectEmpty(state.data) ? (
        <div className="flex flex-col pt-8">
          <StatsTable
            data={state.data}
            columns={['Date', 'Nombre de labellisations']}
          />
        </div>
      ) : (
        <div>Aucune labellisation réalisée</div>
      )}
    </div>
  )
}
