import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth'
import React, { useState } from 'react'
import { onError } from 'shared/utils/web/error'
import { Button } from './components/Button'
import { auth } from './firebase'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  function submit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()
    setError('')
    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      onError(error)
      setError('Accès refusé')
    })
  }

  function googleAuth(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()
    setError('')
    signInWithRedirect(auth, new GoogleAuthProvider()).catch((error) => {
      onError(error)
      setError('Accès refusé')
    })
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-4">
      <h1>Identifiez-vous</h1>
      <Button onClick={googleAuth}>Via Google</Button>
      <h3>OU</h3>
      <form className="flex flex-col items-center gap-2">
        <input
          className="outline-hidden min-w-fit border-0 bg-slate-800 px-2 py-1"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="outline-hidden min-w-fit border-0 bg-slate-800 px-2 py-1"
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          disabled={email.length < 6 || password.length < 6}
          onClick={submit}
        >
          Valider
        </Button>
      </form>
      {error && <div className="rounded-sm bg-red-300 px-4 py-2">{error}</div>}
    </div>
  )
}
