import {
  SCOPE_AVG_STEP_MAPPING,
  extractSerialInstant,
  getSequenceKey,
} from 'common/sequence'
import {
  AddInstantsToLabelingQueueParameters,
  InstantLabels,
  SCOPES,
  Sequence,
  Sequences,
} from 'common/types'
import { ref } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import { QueueEntry, markEntryAsLabeled, useQueue } from 'shared/hooks/useQueue'
import { dataState, loadingState } from 'shared/types/asyncState'
import { fetch_ } from 'shared/utils/fetch'
import { z } from 'zod'
import { auth, database } from '../firebase'
import { get, remove, set, update } from '../firebaseMethods'

async function getSequencesFromURL() {
  const urlParameter = window.location.pathname.replace(/^\//, '')
  const serialInstant = extractSerialInstant(urlParameter)

  if (serialInstant === undefined) return null

  const params = new URLSearchParams(window.location.search)
  const scope = z.enum(SCOPES).nullable().parse(params.get('scope'))

  const source = 'link'
  const why = 'unknown'
  const priority = 1
  const labelersTarget = 3
  const addRandomOffsetToRef = false

  const requestBody: AddInstantsToLabelingQueueParameters = [
    {
      instants: [`${serialInstant.serial}/${serialInstant.instant}`],
      source: source,
      why: why,
      priority: priority,
      labelersTarget: labelersTarget,
      avgStep: SCOPE_AVG_STEP_MAPPING[scope ?? 'mid'],
      addRandomOffsetToRef: addRandomOffsetToRef,
    },
  ]

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }

  const sequences = await fetch_(
    `https://europe-west1-${
      import.meta.env.VITE_PROJECT_ID
    }.cloudfunctions.net/addInstantsToLabelingQueue`,
    options,
  )
    .then((response) => response.json())
    .then((result) => result as Sequences)

  return sequences
}

interface InstantFromURL {
  sequences: Sequences
  sequenceIndex: number
}

async function getInstantFromURL() {
  const parts = window.location.pathname.split('/')
  if (parts.length !== 3) return null
  const [_, sequenceKey, sequenceIndexStr] = parts
  if (!/[A-Za-z0-9]+_[a-z0-9]{16}-[0-9T:+-]+/.test(sequenceKey)) return null
  const sequenceIndex = Number(sequenceIndexStr)
  if (isNaN(sequenceIndex)) return null

  const sequence = await get(`sequences/${sequenceKey}`)

  return { sequences: { [sequenceKey]: sequence }, sequenceIndex }
}

// Warning, must be defined out of useSequence to provide a stable reference to useQueue
// Otherwise results in an infinite re-render loop
const sequencesRef = ref(database, 'sequences')

export const useSequence = () => {
  const userId = auth.currentUser?.uid ?? 'unknown'

  // undefined = no yet set
  // null = no valid data found in URL
  const [sequencesFromURL, setSequencesFromURL] = useState<
    Sequences | null | undefined
  >(undefined)

  const [instantFromURL, setInstantFromURL] = useState<
    InstantFromURL | null | undefined
  >(undefined)

  const queue = useQueue<Sequence>(userId, sequencesRef)

  // Instant index in sequence
  const [sequenceIndex, setSequenceIndex] = useState(0)

  const [startTime, setStartTime] = useState(0)

  let currentSequence: QueueEntry<Sequence> | undefined = undefined

  // Wait for URL to be parsed, otherwise queue item is used
  if (sequencesFromURL !== undefined && instantFromURL !== undefined) {
    // In case a URL is in use
    const urlSequences = sequencesFromURL ?? instantFromURL?.sequences
    if (urlSequences) {
      const key = Object.keys(urlSequences)[0]
      currentSequence = { key, value: urlSequences[key] }
    } else {
      if (!queue.loading && queue.data !== null) {
        currentSequence = queue.data.head
      }
    }
  }

  // Trigger a single url parsing on start
  useEffect(() => {
    async function runEffect() {
      const sequencesFromURL = await getSequencesFromURL()
      setSequencesFromURL(sequencesFromURL)
    }

    runEffect()
  }, [])

  useEffect(() => {
    async function runEffect() {
      const instantFromUrl = await getInstantFromURL()
      setInstantFromURL(instantFromUrl)
      if (instantFromUrl !== null) {
        setSequenceIndex(instantFromUrl.sequenceIndex)
      }
    }

    runEffect()
  }, [])

  // Re-init sequenceIndex time on each sequenceKey change
  useEffect(() => {
    setSequenceIndex(0)
  }, [currentSequence?.key])

  // Re-init start time on each instant change
  useEffect(() => {
    setStartTime(Date.now())
  }, [sequenceIndex])

  const saveLabels = useCallback(
    async (labels: string[]) => {
      if (!currentSequence) throw Error('saveLabels called with no sequence')

      const { serial, source, why, instants, labelerUids } =
        currentSequence.value

      const instant = instants[sequenceIndex]
      const key = getSequenceKey(serial, instant)

      const instantLabels: InstantLabels = {
        ts: Date.now(), // to keep it consistent with startTime which is evaluated locally
        start_ts: startTime,
        labels: labels.sort((labelA, labelB) => {
          return labelA.localeCompare(labelB)
        }),
      }

      if (labelerUids === undefined) {
        // First labelisation of this sequence, create labeledInstant
        const labeledInstant = {
          serial,
          source,
          why,
          instant,
        }
        await update(`labeledInstants/${key}`, labeledInstant)
      }

      await set(`labeledInstants/${key}/labelers/${userId}`, instantLabels)

      // Select next instant in sequence
      // Warning setSequenceIndex is async, compute actual value
      const nextSequenceIndex = sequenceIndex + 1

      if (nextSequenceIndex < instants.length) {
        setSequenceIndex(nextSequenceIndex)
      } else {
        await markEntryAsLabeled(
          sequencesRef,
          currentSequence.key,
          userId,
          () => {
            return Promise.all([
              remove(`sequences/${currentSequence.key}`),
              ...currentSequence.value.instants
                .map((instant) => getSequenceKey(serial, instant))
                .map((key) =>
                  set(`instantsToBeExported/${key}`, true as const),
                ),
            ])
          },
        )

        // Trigger selection of a new sequence
        if (sequencesFromURL) setSequencesFromURL(null)
        else if (instantFromURL) setInstantFromURL(null)
        else {
          if (queue.loading || queue.data === null) return
          queue.data.pop()
        }
      }
    },
    [
      currentSequence,
      sequenceIndex,
      startTime,
      userId,
      sequencesFromURL,
      instantFromURL,
      queue,
    ],
  )

  type UseSequence = {
    sequenceKey: string
    serial: string
    instants: string[]
    sequenceIndex: number
    hint: string | undefined
    saveLabels: typeof saveLabels
  } | null

  if (currentSequence)
    return dataState<UseSequence>({
      sequenceKey: currentSequence.key,
      serial: currentSequence.value.serial,
      instants: currentSequence.value.instants,
      hint: currentSequence.value.hint,
      sequenceIndex,
      saveLabels,
    })

  if (queue.loading) return loadingState()

  return dataState<UseSequence>(null)
}
