import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import React, { useMemo } from 'react'
import { s3BucketName, s3BucketRegion } from 'shared/types/aws'
import { useFirebase } from './hooks/useFirebase'

export const S3Wrapper = ({
  children,
}: {
  children: (s3Client: S3Client) => React.ReactNode
}) => {
  const { loading, error, data } = useFirebase('connections/aws')

  const s3Client = useMemo(() => {
    if (data === null) return null

    const { accessKeyId, secretAccessKey } = data

    return new S3Client({
      credentials: { accessKeyId, secretAccessKey },
      region: s3BucketRegion,
    })
  }, [data])

  return (
    <div>
      {loading ? (
        <div className="py-12 text-center">Chargement...</div>
      ) : error || !s3Client ? (
        <div className="py-12 text-center">Erreur</div>
      ) : (
        children(s3Client)
      )}
    </div>
  )
}

export async function getS3Url(key: string, client: S3Client) {
  const command = new GetObjectCommand({ Bucket: s3BucketName, Key: key })
  const url = await getSignedUrl(client, command, { expiresIn: 3600 })
  return url
}
