import { siteUrl } from 'shared/utils/url'

type Props = {
  currentSoundKey: string
  closePanel: <T>(event?: React.MouseEvent<T>) => void
}

export const LabelsDialog = ({ currentSoundKey, closePanel }: Props) => {
  return (
    <>
      <div
        className="absolute right-0 top-0 flex flex-col items-start gap-3 rounded-xl bg-violet-500 bg-opacity-50 p-3 pr-8"
        onClick={(e) => e.stopPropagation()}
      >
        <a
          href={`${siteUrl('labels')}/${currentSoundKey}`}
          target="_blank"
          rel="noopener noreferrer"
          className="rounded-full bg-violet-500 px-4 py-1 hover:bg-violet-600"
          title="Ouvrir ce son dans l'application de labelisation"
          onClick={closePanel}
        >
          Labeliser ce son
        </a>
      </div>
      <div
        className="absolute right-0 top-0 rounded-full px-2 text-3xl hover:bg-violet-600"
        onClick={closePanel}
      >
        &times;
      </div>
    </>
  )
}
