import { useEffect, useState } from 'react'
import { Labels } from './Labels'
import { TimeLine } from './TimeLine'
import { Button } from './components/Button'
import { useSequence } from './hooks/useSequence'

const ShowDescriptionStorageKey = 'showDescriptions'

export const Main = () => {
  const [selectedLabels, setSelectedLabels] = useState<string[]>([])
  const [prevSelectedLabels, setPrevSelectedLabels] = useState<string[]>([])
  const [showDescriptions, setShowDescriptions] = useState<boolean>(
    JSON.parse(localStorage.getItem(ShowDescriptionStorageKey) ?? 'true'),
  )

  const asyncSequence = useSequence()

  // Reset selected labels on instant index change
  useEffect(() => {
    setSelectedLabels([])
  }, [asyncSequence.data?.sequenceIndex])

  // Reset previously selected labels on sequence change
  useEffect(() => {
    setPrevSelectedLabels([])
  }, [asyncSequence.data?.sequenceKey])

  // change URL on instant change
  useEffect(() => {
    if (
      asyncSequence.loading ||
      asyncSequence.error ||
      asyncSequence.data === null
    )
      return

    const { sequenceKey, sequenceIndex } = asyncSequence.data

    window.history.replaceState(
      null,
      '',
      `${window.location.origin}/${sequenceKey}/${sequenceIndex}`,
    )
  }, [asyncSequence])

  function tag(label: string) {
    const labelIndex = selectedLabels.findIndex((l) => l === label)
    if (labelIndex !== -1) {
      selectedLabels.splice(labelIndex, 1)
      setSelectedLabels([...selectedLabels])
    } else {
      setSelectedLabels([...selectedLabels, label])
    }
  }

  function toggleShowDescription() {
    setShowDescriptions((value) => !value)
    localStorage.setItem(
      ShowDescriptionStorageKey,
      JSON.stringify(!showDescriptions),
    )
  }

  if (asyncSequence.loading)
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Chargement...
      </div>
    )

  if (asyncSequence.data === null)
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Pas de séquence à labeliser
      </div>
    )

  const { sequenceKey, serial, instants, sequenceIndex, hint, saveLabels } =
    asyncSequence.data

  return (
    <div className="relative flex h-screen flex-col">
      <TimeLine
        sequenceKey={sequenceKey}
        serial={serial}
        sequenceIndex={sequenceIndex}
        instants={instants}
      />
      {hint && (
        <div className="gap-2 p-8 text-center font-bold text-yellow-400">
          {`⚠️ ${hint} ⚠️`}
        </div>
      )}
      <Labels
        selectedLabels={selectedLabels}
        prevSelectedLabels={prevSelectedLabels}
        tag={tag}
        showDescriptions={showDescriptions}
      />
      <div className="flex flex-row items-center gap-4 px-4 py-2">
        <Button title="Voir la page d'aide">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/osoai/Page-d-aide-S-quence-149adca485ef4d2f9d98651815810032"
          >
            <b>?</b>
          </a>
        </Button>
        <Button
          title="Enregistrer et passer au suivant"
          disabled={selectedLabels.length === 0}
          primary={true}
          className="flex-1"
          onClick={() => {
            setPrevSelectedLabels(selectedLabels)
            saveLabels(selectedLabels)
          }}
        >
          Valider
        </Button>
        <Button
          title="Afficher la description des labels"
          onClick={toggleShowDescription}
        >
          {showDescriptions ? '⎕' : '⌧'}
        </Button>
      </div>
    </div>
  )
}
