import { getFunctions, httpsCallable } from 'firebase/functions'
import { useCallback, useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { Serial } from 'shared/types/utils'
import {
  GetPredictionsParams,
  GetPredictionsResponseData,
} from 'shared/types/victoria'
import { onError } from 'shared/utils/web/error'
import { app } from '../firebase'

const functions = getFunctions(app, DEFAULT_REGION)
const getPredictions = httpsCallable<
  GetPredictionsParams,
  GetPredictionsResponseData
>(functions, 'getPredictions')

export type Predictions = Record<number, (number | null)[]>

export const usePredictions = (metrics: string[], serial: Serial) => {
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()
  const [predictions, setPredictions] = useState<Predictions>({})

  const addPredictions = useCallback(
    (start: number, end: number) => {
      getPredictions({ metrics, serial, start, end })
        .then((result) => {
          const data = result.data
          setPredictions((predictions) => ({ ...predictions, ...data }))
        })
        .catch(onError)
    },
    [metrics, serial],
  )

  const extendInterval = useCallback(
    (newStart: number, newEnd: number) => {
      if (start === undefined || end === undefined) {
        addPredictions(newStart, newEnd)
        setStart(newStart)
        setEnd(newEnd)
      } else {
        if (newStart < start) {
          addPredictions(newStart, start)
          setStart(newStart)
        }
        if (newEnd > end) {
          addPredictions(end, newEnd)
          setEnd(newEnd)
        }
      }
    },
    [start, end, addPredictions],
  )

  return {
    predictions,
    extendInterval,
  }
}
